<!-- 语法 -->
<template>
  <div class="">
    <dltdan></dltdan>
    <dltfu></dltfu>
  </div>
</template>

<script>
import dltfu from "@/components/wanfa/dltfu.vue";
import dltdan from "@/components/wanfa/dltdan.vue";
//import '../js/font.js' //js文件引入，注意路径
//import {myfun} from '../js/test.js' //js文件引入，注意路径
//import Introduction from '@/views/Introduction.vue'
//import api from '@/js/method'
export default {
  name: "",
  components: {
    dltfu,
    dltdan,
  },
  data() {
    // 数据源
    return {};
  },
  mounted() {},
  computed: {
    sd() {
      return this.$store.state.sd;
    },
  },
  methods: {
    // 函数体部分，js的主要逻辑控制
  },
  props: {},
};
</script>

<style scoped>
/* @import './swiper.css'; */
</style>