<!-- 七乐彩基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2399" width="45" height="45">
                        <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B"
                            p-id="2400"></path>
                        <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
                            fill="#0C7CBE" p-id="2401"></path>
                        <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
                            fill="#EFB918" p-id="2402"></path>
                        <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
                            fill="#14934A" p-id="2403"></path>
                        <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
                            fill="#D7282A" p-id="2404"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国体育彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>大 乐 透 复 式 投 注 表</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" colspan="2" class="ranks_a">复式</th>
                        <th colspan="16" class="ranks_b">后区个数</th>
                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item , i) in lan" :key="i">{{item}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th rowspan="35" class="ranks_a">前区个数</th>
                    </tr>
                    <tr v-for="(item ,i) in red" :key='i'>
                        <!-- 三区走势图 -->
                        <td >
                            <div>
                                {{item}}
                            </div>
                        </td>
                        <td class="b-lan" v-for="(item1 ,i) in lan" :key='i' >
                            <div>
                                {{mon(item-5+1,item,5,mon(item1-2+1,item1,2,1)/2)}}
                              
                            </div>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    //import axios from 'axios'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'qlc-basic',
        components: {

        },
        data() { // 数据源
            return {
                red: [6,7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30,31,32,33,34,35],
                lan: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            }
        },
        mounted() {
            this.toTop()
        },
        computed: {


        },
        methods: { // 函数体部分，js的主要逻辑控制  
            toTop() {
                document.documentElement.scrollTop = 0;
            },
            mon(min, max, wangs, langs) {
				var s = 1;
				for (var n = min - 1; n < max; n++) {
					s = s * (n + 1)
				}
				for (var n1 = wangs; n1 > 0; n1--) {
					s = s / n1
				}
				return s * langs * 2

			}
        },
        props: {
        },
        watch: {

        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
         background-color: rgb(224, 202, 5);
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 30px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 60px;
        color: rgb(76, 0, 255);
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .b-red {
        height: 23px;
        background-color: rgb(215, 191, 224);
    }

    .b-lan {
        background-color: rgb(221, 220, 171);
    }

    .q-red {
        background-color: rgb(163, 45, 100);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: rgb(67, 67, 179);
        color: #fff;
        border-radius: 15px;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }





    .no {
        display: none;
    }
</style>